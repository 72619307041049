import { useCallback, useState } from 'react';
import cx from 'classnames';
import { ImageList, ImageListItem } from '@mui/material';
import AlbumCover from '../../assets/img/cover.png';
import ParaglidingCover from '../../assets/img/paragliding.jpg';
import CatCrawlCover from '../../assets/img/cat_crawl_tea_time.jpg';
import '../../assets/css/home.scss';
import '../../assets/css/home-mobile.scss';
import MainProfile from '../../assets/img/profiles/main_profile_v3.jpg';
import MainProfilePc from '../../assets/img/profiles/main_profile_v3_pc.jpg';
import Profile1 from '../../assets/img/profiles/profile_v3_1.jpg';
import Profile2 from '../../assets/img/profiles/profile_v3_2.jpg';
import Profile3 from '../../assets/img/profiles/profile_v3_3.jpg';
import Profile4 from '../../assets/img/profiles/profile_v3_4.jpg';
import Profile5 from '../../assets/img/profiles/profile_v3_5.jpg';
import CdStore from '../../assets/img/cd_store.png';
import Intagram from '../../assets/img/sns/instagram.png';
import Twitter from '../../assets/img/sns/twitter.png';
import Youtube from '../../assets/img/sns/youtube.png';

const initialViewPortWidth = document.documentElement.clientWidth || window.innerWidth;
const isMobileInitial = initialViewPortWidth <= 480;

const Home = () => {
  const trackData = [
    {
      title: '1. 동심원(Circles) - 09:00 AM',
      description: (
        <>
          깊은 숲 푸른 잎 사이로 새어들어오는 햇살
          <br />
          맑은 호수에 퍼져나가는 파문 <br />
          같은 마음에서 출발하고 언젠간 다시 만나게 되는
          <br />
          <br />
          Vocal 황인경
          <br /> Guitar 황인경
          <br /> Bass 눙눙
          <br /> Bass Arrange 눙눙, 황인경 <br />
          Drums 김진철 <br />
          Chorus 황인경
          <br />
          Vocal Recording 토마토 스튜디오
        </>
      ),
    },
    {
      title: '2. 자연사 박물관(Natural History Museum) - 10:30 AM',
      description: (
        <>
          늘 어딘가 엉성했던 과거의 모든 나와 <br />
          박물관 유리관 속에 남겨진 모든 너에게
          <br /> 이제는 기억 속에서만 살아가는 나의 아이
          <br />
          <br />
          composed & words & arranged by 황인경
          <br />
          Vocal 황인경 <br />
          Guitar 황인경 <br />
          Bass 정수민 <br />
          Drums 김진철 <br />
          Chorus 황인경, 박여름, 김말이 <br />
          Vocal Recording 안태봉(오르카 스튜디오)
        </>
      ),
    },
    {
      title: '3. 날씨 때문에(Weather)(2023 ver.) - 11:30 AM',
      description: (
        <>
          제주의 햇볕은 내 등을 덥히고
          <br /> 바람이 마음을 간지럽히던
          <br /> 어느 하루 평화로운 오전의 날씨
          <br />
          <br />
          composed & words & arranged by 황인경
          <br />
          Vocal 황인경 <br />
          Guitar 황인경 <br />
          Bass 황인경 <br />
          Drums 장한세 <br />
          Accordion 최솔 <br />
          Chorus 황인경, 박여름
          <br />
          Guitar, Vocal Recording 천학주(머쉬룸레코딩 스튜디오)
        </>
      ),
    },
    {
      title: '4. 탠저린(Tangerine)(Remastered 2023) - 02:30 PM',
      description: (
        <>
          함께 걸었던 모든 길은 <br />
          알록달록한 선이 되어 내 몸에 남았다
          <br /> 기억이 새긴 타투 <br />
          함께 추던 춤 함께 꾸던 꿈
          <br />
          <br />
          composed by 황인경, 김예슬, 눙눙 <br />
          words by 황인경, 김예슬 <br />
          arranged by 황인경, 김예슬, 눙눙 <br />
          Vocal 황인경 <br />
          Guitar 김예슬 <br />
          Bass 눙눙 <br />
          Drums 김진철 <br />
          Chorus 황인경 <br />
          Recording 천학주(머쉬룸레코딩 스튜디오)
        </>
      ),
    },
    {
      title: '5. 파트타임 히어로즈(Part-time Heroes)(Remastered 2023) - 4:00 PM',
      description: (
        <>
          친구를 괴롭히는 세상이 미워서
          <br /> 나는 자경단이 되기로 했다
          <br />
          <br />
          composed by 황인경, 김예슬, 눙눙 <br />
          words by 황인경, 김예슬 <br />
          arranged by 황인경, 김예슬, 눙눙
          <br />
          Vocal 황인경 <br />
          Guitar 김예슬 <br />
          Bass 눙눙 <br />
          Drums 김진철 <br />
          Chorus 황인경, 김예슬, 눙눙 <br />
          Recording 천학주(머쉬룸레코딩 스튜디오) <br />
        </>
      ),
    },
    {
      title: '6. 장마(Monsoon) - 6:45 PM',
      description: (
        <>
          돌아온다, 돌아오지 않는다 <br />
          하나씩 떼다 보니 어느새 하나만 남은 이파리
          <br />
          <br />
          composed & words & arranged by 황인경 <br />
          Vocal 황인경 <br />
          Guitar 황인경 <br />
          Bass 정수민 <br />
          Drums 김진철 <br />
          Trumpet 최규민 <br />
          Guitar, Trumpet, Vocal Recording 천학주(머쉬룸레코딩 스튜디오)
        </>
      ),
    },
    {
      title: '7. 어둠의 저편 (feat. 박여름)(After Dark (feat. Summer Park)) - 9:00 PM',
      description: (
        <>
          *곡 제목에 무라카미 하루키의 동명의 소설을 인용하였습니다.
          <br />
          <br />
          그 모든 기억은 내 마음을 더 강하게 해주었다
          <br />
          <br /> 우리의 침묵은 어둡지 않아서 <br />
          가장 어두운 밤에도 좌초되지 않았다
          <br /> 별자리를 담은 눈, 나의 길잡이 별 <br />
          <br />
          돛을 가득 채운 바람처럼
          <br /> 차 안으로 날아드는 공기는 <br />
          우리를 더 먼 곳으로 이끌었다
          <br />
          <br />
          composed & words & arranged by 황인경
          <br />
          Vocal 황인경, 박여름 <br />
          Guitar 황인경 <br />
          Bass 황인경 <br />
          Drums 김진철 <br />
        </>
      ),
    },
    {
      title: '8. 비행기(Airplane) - 10:45 PM',
      description: (
        <>
          다른 시간대를 산다는 것<br /> 물속과 물 밖의 경계
          <br /> 두려움과 용기의 국경
          <br />
          <br />
          composed & words & arranged by 황인경 <br />
          Vocal 황인경 <br />
          Guitar 황인경 <br />
          Bass 눙눙 <br />
          Drums 김진철 <br />
          Vocal Recording 안태봉(오르카 스튜디오) <br />
        </>
      ),
    },
    {
      title: '9. 고요한 슬픔과 부드러운 밤의 온기(Quiet Grief and Gentle Warmth of Night) - 12:15',
      description: (
        <>
          요란하게 터지던 웃음은 고요한 슬픔으로 스미고 <br />
          시끄럽던 낮의 열기는 밤의 품에서 부드럽게 식어갔다 <br />
          증명할 순 없지만 분명히 거기 있었던 어떤 마음
          <br />
          <br />
          composed & words & arranged by 황인경 <br />
          Vocal 황인경 <br />
          Guitar 황인경 <br />
          Bass 정수민 <br />
          Drums 장한세 <br />
          Vocal Recording 민상용(스튜디오로그)
        </>
      ),
    },
    {
      title: '10. 타고난 길치(Lost)(2023 ver.) - 2:30 AM',
      description: (
        <>
          오늘도 길을 잃고 헤매는 사람에게 <br />
          당신 혼자만 그런 건 아니라고
          <br />
          <br />
          composed & words & arranged by 황인경 <br />
          Vocal 황인경 <br />
          Guitar 황인경 <br />
          Bass 황인경 <br />
          Drums 장한세 <br />
          Violin 황예지 <br />
          Piano, Cello 이혜지 <br />
          Piano, Cello, Guitar Recording 천학주(머쉬룸레코딩 스튜디오) <br />
          Violin Recording 민상용(스튜디오로그) <br />
          Vocal Recording 안태봉(오르카 스튜디오)
          <br />
        </>
      ),
    },
  ];
  const [openedComment, openComment] = useState(null);
  const [isMobile, setIsMobile] = useState(isMobileInitial);

  const profilePictures = [
    { img: Profile1, title: 'profile 1' },
    ...(isMobile ? [] : [{ img: Profile2, title: 'profile 2' }]),
    { img: Profile3, title: 'profile 3' },
    { img: Profile4, title: 'profile 4' },
    { img: Profile5, title: 'profile 5' },
  ];

  const detectMobile = useCallback(() => {
    const viewPortWidth = document.documentElement.clientWidth || window.innerWidth;
    setIsMobile(viewPortWidth <= 480);
  }, [setIsMobile]);

  window.addEventListener('resize', detectMobile);

  const handleCommentClick = useCallback(
    (event) => {
      const trackNumber = Number(event.target.dataset.trackNumber);
      if (openedComment === trackNumber) {
        openComment(null);
      } else {
        openComment(trackNumber);
      }
    },
    [openedComment, openComment],
  );

  return (
    <div className='home'>
      <header className='header'>
        <div className='header_inner'></div>
      </header>
      <main className='container'>
        <section className='main_profile'>
          <img
            src={isMobile ? MainProfile : MainProfilePc}
            loading='lazy'
            alt='artist main profile 1'
          />
        </section>
        <section className='artist_instruction'>
          <p className='title'>전기뱀장어</p>
          <p className='description'>
            송라이터이자 보컬 겸 기타리스트인 황인경의 밴드 전기뱀장어는 2011년 첫 앨범 발매 이후
            서울을 중심으로 활동을 이어왔다.
            <br /> 청량한 기타 사운드와 공감을 불러일으키는 가사로 사랑받고 있다.
          </p>
          <p className='title'>Electriceels</p>
          <p className='description'>
            ‘Electriceels’ is an indie band based in Seoul, since 2011. <br /> Hwang Inkyoung is a
            singer-songwriter and the only member of the band.
            <br /> Their fans love their unique, clear, and refreshing sound.
          </p>
          <div className='sns'>
            <ul>
              <li>
                <a href='https://twitter.com/Electriceels_ko' target='_blank' rel='noreferrer'>
                  <img src={Twitter} loading='lazy' alt='twitter'></img>
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/electriceels_band'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={Intagram} loading='lazy' alt='instagram'></img>
                </a>
              </li>
              <li>
                <a
                  href='https://www.youtube.com/@electriceels_band'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={Youtube} loading='lazy' alt='youtube'></img>
                </a>
              </li>
            </ul>
          </div>
        </section>
        <h1 className='album_introduction_title'>
          Electriceels Single Album [Tea Time for Cats and Magpies]
        </h1>
        <section className='album_introduction' style={{ marginBottom: '80px' }}>
          <img className='album_cover' src={CatCrawlCover} alt='fourth album cover' />
          <div>
            <strong className='title'>고양이와 까치를 위한 티타임</strong>
            <div className='description'>
              도서관 야외 테라스에서 책을 읽던 어느 봄날, 햇살이 눈 부셔 글자가 잘 보이지 않아 책을
              덮었습니다. 차를 한 모금 마시고 눈을 감으니, 시각이 아닌 다른 감각들이 깨어나는
              듯했습니다. 차의 향기와 바람의 감촉, 나뭇잎이 부딪히는 소리와 까치의 목소리가 더
              생생하게 느껴졌습니다.
              <br />
              <br />
              때로는 이렇게 눈을 감는 것만으로도 짐짓 잊고 있던 행복을 발견할 수 있나 봅니다.
              시각에만 경도된 것 같은 하루하루 속에서 발견한 이 작은 틈이, 인간의 이런저런 감각 수용
              기관은 여러 각도에서 이 세상을 느낄 수 있다는 사실이 즐거웠습니다. 그날 도서관에서
              책을 많이 읽지 못한 채 집으로 돌아가야 했지만 전혀 아쉽지 않았습니다.
            </div>
            <br />
            <strong className='title'>CREDIT</strong>
            <div className='description'>
              Lyrics, Composition, Arrangement, Producing, Mixing, Vocals, Guitar, Synthesizer
              황인경 | Bass 눙눙 | | Guitar Recording 천학주 (머쉬룸레코딩 스튜디오) | Vocal
              Recording 안태봉 (오르카레코딩스튜디오) | Mastering 강승희 (소닉코리아 서울숲스튜디오)
              | Music Video 훌프 | Cover Design 추지원
            </div>
          </div>
        </section>
        <h1 className='album_introduction_title'>Electriceels Single Album [Paragliding]</h1>
        <section className='album_introduction' style={{ marginBottom: '80px' }}>
          <img className='album_cover' src={ParaglidingCover} alt='third album cover' />
          <div>
            <strong className='title'>바람이 되는 방법, 전기뱀장어 싱글 [패러글라이딩]</strong>
            <div className='description'>
              2018년 네팔 여행 중 잊을 수 없는 하나는 패러글라이딩을 해본 일입니다. 저 멀리
              안나푸르나의 만년설이 빛났고 아래를 내려다보면 페와 호수가 햇볕에 일렁였습니다. 고산
              지대의 독수리가 두 날개를 펼치고 바로 옆에서 날고 있었습니다. 두 발이 땅에 닿지 않는
              묘한 기분에 나 자신이 바람이 된 듯했습니다.
              <br />
              <br />
              저는 언제나 바람에 매료되곤 합니다. 바람은 눈에 보이지는 않지만, 주변의 것을 흔들며
              자신을 드러냅니다. 바람이 불면 온도와 냄새가 섞이고 경계가 흐릿해집니다. 멈춰 있지
              않는다는 것, 정체되지 않은 상태. 히말라야산맥 위를 활강하는 저에게 바람은 자유의
              본질을 가르쳐 주었습니다.
              <br />
              <br />
              선명하게 기억하는 어느 하루, 저는 자유롭게 하늘에 궤적을 그렸습니다. 매일매일 두 발을
              땅 위에 붙이고, 좁은 시야를 가지고 살아가는 육상 동물의 답답함도 잊고 말이죠. 그날의
              바람을 되새기며, 비행의 메뉴얼을 노래에 담았습니다. 첫 번째, 참았던 숨을 뱉어내고. 두
              번째, 감은 눈을 뜨고. 세 번째, 움츠렸던 두 팔을 펴면.
            </div>
            <br />
            <strong className='title'>CREDIT</strong>
            <div className='description'>
              Lyrics, Composition, Producing 황인경 | Arrangement 황인경, Yohna | Vocals, Guitar
              황인경 | Drums 김진철 | Bass 눙눙 | Synthesizer Yohna | Drum Recording 천학주
              (머쉬룸레코딩 스튜디오) | Mixing, Mastering 신재민(필로스플래닛) | Cover Design 추지원
            </div>
          </div>
        </section>
        <h1 className='album_introduction_title'>Electriceels 3rd Studio Album [Circles]</h1>
        <section className='album_introduction'>
          <img className='album_cover' src={AlbumCover} alt='third album cover' />
          <div>
            <strong className='title'>
              마음속에 퍼져나가는 물결, 전기뱀장어 정규 3집 [동심원]
            </strong>
            <div className='description'>
              전기뱀장어의 새 앨범 [동심원]은 2집 [Fluke](2016) 이후 7년 만에 발표하는 정규
              앨범이다. <br />
              본작은 최근 멤버 변동 이후 처음으로 발표하는 앨범으로, 밴드의 유일한 멤버인 황인경의
              음악적 지향이 고스란히 녹아있다. 선공개 곡 ‘자연사 박물관’을 발표하면서 청량한
              사운드와 재치 있는 가사로 여전한 매력을 보여주더니 열 곡이 실린 정규 앨범 [동심원]을
              들고 돌아왔다. 한입 베어 물면 수박향이 날 것 같은 이 여름의 한 조각을 마음껏 만끽해
              보자.
              <br />
              <br />
              이번 앨범에서 황인경은 싱글로 발표한 바 있는 두 곡(‘탠저린’, ‘파트타임 히어로즈’)을
              제외하고는 전곡의 작곡, 작사, 편곡, 프로듀싱을 온전히 혼자 담당하였고, 보컬은 물론
              모든 기타 연주와 일부 베이스와 건반 연주까지 황인경의 손을 거쳤다.
              <br />
              <br />
              앨범에는 총 열 개의 트랙이 실려있다. <br />
              ‘동심원’, ‘자연사 박물관’, ‘장마’, ‘어둠의 저편’, ‘비행기’는 온전히 새롭게 선보이는
              곡이다. ‘날씨 때문에’, ‘타고난 길치’는 기존에 솔로로 발표했던 음원을 리메이크한
              트랙들이다. 편곡과 연주 모두 새롭게 작업하였다. ‘고요한 슬픔과 부드러운 밤의 온기’같은
              경우 황인경이 솔로 아티스트로 발표했던 곡 ‘요란한 웃음과 시끄러운 낮의 열기’의 쌍둥이
              곡이라 할 수 있다. 가사는 전작과 닮았지만 멜로디와 편곡이 달라져 새로운 느낌을 주는
              트랙이다. <br />
              최근에 전기뱀장어가 싱글로 발표했던 ‘탠저린’, ‘파트타임 히어로즈’는 리마스터링 작업을
              거쳐 본 앨범에 수록되었다.
            </div>
          </div>
        </section>
        <section className='track_list'>
          <p className='title'>TRACK LIST</p>
          <p className='description'>
            앨범에는 총 열 곡을 담았는데, 어느 여름날의 하루를 생각하며 시간의 흐름에 따라 순서를
            정했습니다. <br />
            상쾌한 아침 공기 같은 노래가 있고, 한낮의 넘치는 에너지를 품은 곡이 있고, 늦은 밤 조용히
            읊조리는 노래가 있습니다.
            <br />
            <br />각 곡을 누르시면 {`<황인경의 곡별 코멘트>`}를 볼 수 있습니다.
          </p>
          <ul>
            {trackData.map((item, index) => {
              return (
                <li key={item.title}>
                  <p className='song_title' onClick={handleCommentClick} data-track-number={index}>
                    {item.title}
                  </p>
                  <p className={cx('song_description', { is_opened: index === openedComment })}>
                    {item.description}
                  </p>
                </li>
              );
            })}
          </ul>
          <p className='small_title'>CREDIT</p>
          <div className='credit_description'>
            <p style={{ marginTop: 0 }}>Produced by 황인경</p>
            <p>Executive Producer 전기뱀장어 </p>
            <p>Drum Recording 천학주(머쉬룸레코딩 스튜디오) </p>
            <p>Mixed by 신재민(필로스플래닛) (Track 1, 2, 3, 6, 7, 8, 9, 10)</p>
            <p>Mixed by 천학주(머쉬룸레코딩 스튜디오) (Track 4, 5)</p>
            <p>Mastered by 신재민(필로스플래닛) </p>
            <br />
            <br />
            <p>Art Director : 추지원</p>
            <p>Photographer : 채보경</p>
            <p>‘동심원’ MV Director : 심윤보(엘브즈 스튜디오)</p>
            <p>‘자연사 박물관’ MV Director : 김말이, 박여름</p>
            <br />
            <p>A&R 이청, 이소정, 김정 </p>
            <p>Chief Director 이소영 </p>
            <p>Promotion & Marketing 유어썸머 Your Summer </p>
          </div>
          <p className='small_title'>STREAMING</p>
          <div className='streaming_description'>
            <ul style={{ marginBottom: 0 }}>
              <li style={{ marginTop: 0 }}>
                <a href='https://t.co/2CwDewTCqo' target='_blank' rel='noreferrer'>
                  Melon
                </a>
              </li>
              <li>
                <a href='https://t.co/w8jJ4S69Np' target='_blank' rel='noreferrer'>
                  Genie
                </a>
              </li>
              <li>
                <a href='https://t.co/oOsYAMDEIU' target='_blank' rel='noreferrer'>
                  Bugs
                </a>
              </li>
              <li>
                <a href='https://t.co/fJOKMGdCL6' target='_blank' rel='noreferrer'>
                  VIBE
                </a>
              </li>
              <li>
                <a href='https://t.co/H8mEHhOapo' target='_blank' rel='noreferrer'>
                  FLO
                </a>
              </li>
              <li>
                <a href='https://t.co/1GZnMrJpGq' target='_blank' rel='noreferrer'>
                  Apple Music
                </a>
              </li>
              <li>
                <a href='https://t.co/eXlgUUQGdR' target='_blank' rel='noreferrer'>
                  YT Music
                </a>
              </li>
              <li>
                <a href='http://t2m.kr/lNdaz' target='_blank' rel='noreferrer'>
                  Spotify
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className='videos'>
          <p className='title'>OFFICIAL MUSIC VIDEO</p>
          <div className='video_item'>
            <iframe
              width={isMobile ? '100%' : '700px'}
              height={isMobile ? '180' : '420'}
              src='https://www.youtube.com/embed/lnm4Z7aF10I?si=SFlosDcsnj4T3WKk'
              title='고양이와 까치를 위한 티타임(Tea Time for Cats and Magpies)'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            />
            <p className='mv_title'>고양이와 까치를 위한 티타임(Tea Time for Cats and Magpies)</p>
            <p className='mv_lyrics'>"눈이 부셔 책을 덮어 바람을 들어봐"</p>
          </div>
          <div className='video_item'>
            <iframe
              width={isMobile ? '100%' : '700px'}
              height={isMobile ? '180' : '420'}
              src='https://www.youtube.com/embed/8VnWFfaZqbc?si=mvws4Q6t8puaV-VS'
              title='패러글라이딩(Paragliding)'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            />
            <p className='mv_title'>패러글라이딩(Paragliding)</p>
            <p className='mv_lyrics'>
              "움츠렸던 두 팔을 펴면 어느새 저 파란 하늘을 날고 있을 거야"
            </p>
          </div>
          <div className='video_item'>
            <iframe
              width={isMobile ? '100%' : '700px'}
              height={isMobile ? '180' : '420'}
              src='https://www.youtube.com/embed/IpEIW99QweM?si=6hWltYIz0SkZ2pX9'
              title='파트타임 히어로즈(Part-time Heroes)'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            />
            <p className='mv_title'>파트타임 히어로즈(Part-time Heroes)</p>
            <p className='mv_lyrics'>
              "해가 지면 지친 나의 가면을 벗고 신발 끈을 고쳐 매 시작된 모험"
            </p>
          </div>
          <div className='video_item'>
            <iframe
              width={isMobile ? '100%' : '700px'}
              height={isMobile ? '180' : '420'}
              src='https://www.youtube.com/embed/QLoujqXZaqw'
              title='동심원(Circles)'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            />
            <p className='mv_title'>동심원(Circles)</p>
            <p className='mv_lyrics'>"너의 얼굴 옆으로 상영되는 저 바다"</p>
          </div>
        </section>
        <section className='merchandise'>
          <img src={CdStore} loading='lazy' alt='online stores' />
          <div>
            <p className='title'>ONLINE STORES</p>
            <ul>
              <li>
                <a
                  href='https://www.yes24.com/Product/Goods/119568075'
                  target='_blank'
                  rel='noreferrer'
                >
                  YES24
                </a>
              </li>
              <li>
                <a
                  href='https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=318652531'
                  target='_blank'
                  rel='noreferrer'
                >
                  Aladdin
                </a>
              </li>
              <li>
                <a
                  href='https://hottracks.kyobobook.co.kr/ht/record/detail/8809696009975'
                  target='_blank'
                  rel='noreferrer'
                >
                  HOTTRACKS
                </a>
              </li>
              <li>
                <a href='bit.ly/43spnPz' target='_blank'>
                  Apple Music
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className='photos'>
          <ImageList sx={{ width: '100%' }} variant='woven' cols={isMobile ? 2 : 3} gap={8}>
            {profilePictures.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.img}?w=161&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading='lazy'
                />
              </ImageListItem>
            ))}
          </ImageList>
        </section>
        <footer>2023 Electriceels © All right reserved</footer>
      </main>
    </div>
  );
};

export default Home;
